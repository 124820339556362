import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../../context/auth/AuthContext'
import handleResponse from '../../../../../handleResponse'
import parseErrorMessage from '../../../../../parseErrorMessage'
import Moment from 'react-moment'

export default function EduSeriesEpisodesIndex() {
  const params = useParams()

  const [token, setToken] = useContext(AuthContext)

  const [series, setSeries] = useState(null)
  const [episodes, setEpisodes] = useState(null)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const loadEpisodes = () => {
    fetch('/api/edge/admin/edu/series/' + params.series + '/episodes', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setEpisodes(data)
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  useEffect(() => {
    fetch('/api/edge/admin/edu/series/' + params.series, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setSeries(data)
        loadEpisodes()
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [params, setSeries, setEpisodes, token, setToken, setError])

  const remove = (id, event) => {
    event.preventDefault()
    if (!confirm('Remove?')) {
      return
    }
    fetch('/api/edge/admin/edu/series/' + params.series + '/episodes/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
        loadEpisodes()
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/edu/series">Series</Link>
          </li>
          {series ? (
            <li className="breadcrumb-item">
              <Link to={'/edu/series/' + series.id}>
                {series.content.title}
              </Link>
            </li>
          ) : null}
          <li className="breadcrumb-item active">Episodes</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {series ? (
          <div>
            <p className="controls">
              <Link
                to={'/edu/series/' + series.id + '/episodes/create'}
                className="btn btn-primary"
              >
                Create
              </Link>
            </p>

            {episodes ? (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Slug</th>
                    <th>Authors</th>
                    <th>Sort</th>
                    <th>Publish</th>
                    <th style={{ textAlign: 'center' }}>Free</th>
                    <th style={{ textAlign: 'center' }}>Demo</th>
                    <th style={{ textAlign: 'center' }}>Status</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {episodes.map((episode) => (
                    <tr key={episode.id}>
                      <td>
                        <Link
                          to={
                            '/edu/series/' +
                            series.id +
                            '/episodes/' +
                            episode.id
                          }
                        >
                          {episode.title}
                        </Link>
                      </td>
                      <td>{episode.slug}</td>
                      <td>
                        {episode.authors.map((author) => (
                          <>
                            {author ? (
                              <span
                                key={author.id}
                                className="badge badge-primary"
                              >
                                {author.name}
                              </span>
                            ) : null}
                          </>
                        ))}
                      </td>
                      <td>{episode.sort}</td>
                      <td>
                        {episode.publish_date ? (
                          <Moment format="YYYY-MM-DD HH:mm:ss">
                            {episode.publish_date}
                          </Moment>
                        ) : null}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {episode.free ? (
                          <span className="badge badge-success">Free</span>
                        ) : null}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {episode.demo ? (
                          <span className="badge badge-success">Demo</span>
                        ) : null}
                      </td>
                      <td style={{ textAlign: 'center' }}>{episode.status}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={(e) => remove(episode.id, e)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}
