import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'

export default function EduSeriesIndex() {
  const [token, setToken] = useContext(AuthContext)

  const [series, setSeries] = useState(null)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const load = () => {
    fetch('/api/edge/admin/edu/series', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setSeries(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }

  useEffect(() => {
    load()
  }, [token, setToken, setSeries, setError])

  const reloadVideos = (event) => {
    event.preventDefault()
    if (!confirm('Reload Videos?')) {
      return
    }
    fetch('/api/edge/admin/edu/series/reload-videos', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const remove = (id, event) => {
    event.preventDefault()
    if (!confirm('Remove?')) {
      return
    }
    fetch('/api/edge/admin/edu/series/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
        load()
      })
      .catch((error) => {
        this.error = parseErrorMessage(error)
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Series</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        <p className="controls">
          <Link to="/edu/series/create" className="btn btn-primary">
            Create
          </Link>
          <button
            type="button"
            className="btn btn-success"
            onClick={reloadVideos}
          >
            Reload Videos
          </button>
        </p>

        {series ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Slug</th>
                  <th>Sort</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {series.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Link to={'/edu/series/' + item.id}>{item.title}</Link>
                    </td>
                    <td>{item.slug}</td>
                    <td>{item.sort}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={(e) => remove(item.id, e)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}
