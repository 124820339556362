import React, { useContext, useState } from 'react'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import parseValidationErrors from '../../../parseValidationErrors'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'

function ProductCreate() {
  const [token, setToken] = useContext(AuthContext)

  const [formData, setFormData] = useState({
    slug: '',
    title: '',
    date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
    endPrice: '',
    startDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
    interval: '',
    increment: '',
    clientDiscount: '',
  })

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('/api/edge/admin/product/products', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(formData),
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/product/products">Products</Link>
          </li>
          <li className="breadcrumb-item active">Create</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        <form method="product" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              name="slug"
              value={formData.slug}
              onChange={handleChange}
              type="text"
              className={'form-control' + (errors.slug ? ' is-invalid' : '')}
              placeholder="Slug"
              required
            />
            <div className="invalid-feedback">{errors.slug}</div>
          </div>
          <div className="form-group">
            <input
              name="title"
              value={formData.title}
              onChange={handleChange}
              type="text"
              className={'form-control' + (errors.title ? ' is-invalid' : '')}
              placeholder="Title"
              required
            />
            <div className="invalid-feedback">{errors.title}</div>
          </div>
          <div className="form-group">
            <input
              name="endPrice"
              value={formData.endPrice}
              onChange={handleChange}
              type="number"
              className={
                'form-control' + (errors.endPrice ? ' is-invalid' : '')
              }
              placeholder="Start Price"
              required
            />
            <div className="invalid-feedback">{errors.endPrice}</div>
          </div>
          <div className="form-group">
            <input
              name="date"
              value={formData.date}
              onChange={handleChange}
              type="datetime"
              className={'form-control' + (errors.date ? ' is-invalid' : '')}
              placeholder="Date"
              required
            />
            <div className="invalid-feedback">{errors.date}</div>
          </div>
          <div className="form-group">
            <input
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              type="datetime"
              className={
                'form-control' + (errors.startDate ? ' is-invalid' : '')
              }
              placeholder="Start Date"
              required
            />
            <div className="invalid-feedback">{errors.startDate}</div>
          </div>
          <div className="form-group">
            <input
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              type="datetime"
              className={'form-control' + (errors.endDate ? ' is-invalid' : '')}
              placeholder="End Date"
              required
            />
            <div className="invalid-feedback">{errors.endDate}</div>
          </div>
          <div className="form-group">
            <input
              name="interval"
              value={formData.interval}
              onChange={handleChange}
              type="number"
              className={
                'form-control' + (errors.interval ? ' is-invalid' : '')
              }
              placeholder="Interval Days"
              required
            />
            <div className="invalid-feedback">{errors.interval}</div>
          </div>
          <div className="form-group">
            <input
              name="increment"
              value={formData.increment}
              onChange={handleChange}
              type="number"
              className={
                'form-control' + (errors.increment ? ' is-invalid' : '')
              }
              placeholder="Increment"
              required
            />
            <div className="invalid-feedback">{errors.increment}</div>
          </div>
          <div className="form-group">
            <input
              name="clientDiscount"
              value={formData.clientDiscount}
              onChange={handleChange}
              type="number"
              className={
                'form-control' + (errors.clientDiscount ? ' is-invalid' : '')
              }
              placeholder="Client Discount"
              required
            />
            <div className="invalid-feedback">{errors.clientDiscount}</div>
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default ProductCreate
