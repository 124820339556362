import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'

export default function EduGroupsIndex() {
  const [token, setToken] = useContext(AuthContext)

  const [groups, setGroups] = useState(null)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const load = () => {
    fetch('/api/edge/admin/edu/groups', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setGroups(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }

  useEffect(() => {
    load()
  }, [token, setToken, setGroups, setError])

  const remove = (id, event) => {
    event.preventDefault()
    if (!confirm('Remove?')) {
      return
    }
    setSuccess(null)
    fetch('/api/edge/admin/edu/groups/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
        load()
      })
      .catch((error) => {
        this.error = parseErrorMessage(error)
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Groups</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        <p className="controls">
          <Link to="/edu/groups/create" className="btn btn-primary">
            Create
          </Link>
        </p>

        {groups ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Sort</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {groups.map((group) => (
                  <tr key={group.id}>
                    <td>
                      <Link to={'/edu/groups/' + group.id}>{group.name}</Link>
                    </td>
                    <td>{group.sort}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={(e) => remove(group.id, e)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}
