import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../context/auth/AuthContext'
import handleResponse from '../../handleResponse'
import parseErrorMessage from '../../parseErrorMessage'
import usePage from '../../usePage'

export default function AuthorsIndex() {
  const [token, setToken] = useContext(AuthContext)

  const [authors, setAuthors] = useState(null)
  const [error, setError] = useState(null)

  const page = usePage()

  useEffect(() => {
    fetch('/api/edge/admin/authors?page=' + page, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setAuthors(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [page, token, setToken, setAuthors, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Authors</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}
        <p className="controls">
          <Link to="/authors/create" className="btn btn-primary">
            Create
          </Link>
        </p>
        {authors ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Site</th>
                </tr>
              </thead>
              <tbody>
                {authors.map((author) => (
                  <tr key={author.id}>
                    <td>
                      <img src={author.photo} alt="" />
                    </td>
                    <td>
                      <Link to={'/authors/' + author.id}>{author.name}</Link>
                    </td>
                    <td>{author.email}</td>
                    <td>{author.site}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}
