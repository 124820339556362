import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../context/auth/AuthContext'
import handleResponse from '../../../../handleResponse'
import parseErrorMessage from '../../../../parseErrorMessage'
import parseValidationErrors from '../../../../parseValidationErrors'

function CorporateInvoicesPay() {
  const [token, setToken] = useContext(AuthContext)
  const params = useParams()

  const [invoice, setInvoice] = useState(null)

  const [formData, setFormData] = useState({
    date: new Date().toISOString().slice(0, 10),
    time: new Date().toISOString().slice(11, 16),
    transaction: '',
  })

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  useEffect(() => {
    fetch('/api/edge/admin/corporate/invoices/' + params.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setInvoice(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [token, setToken, setInvoice, setError])

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('/api/edge/admin/corporate/invoices/' + invoice.id + '/pay', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        date: formData.date + ' ' + formData.time,
        transaction: formData.transaction,
      }),
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch((error) => {
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/corporate/invoices">Invoices</Link>
          </li>
          {invoice ? (
            <>
              <li className="breadcrumb-item">
                <Link to={'/corporate/invoices/' + invoice.id}>
                  {invoice.id}
                </Link>
              </li>
              <li className="breadcrumb-item active">Pay</li>
            </>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {invoice ? (
          <div>
            <form method="invoice" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  type="date"
                  className={
                    'form-control' + (errors.date ? ' is-invalid' : '')
                  }
                  required
                />
                <div className="invalid-feedback">{errors.date}</div>
              </div>
              <div className="form-group">
                <input
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  type="time"
                  className={
                    'form-control' + (errors.date ? ' is-invalid' : '')
                  }
                  required
                />
                <div className="invalid-feedback">{errors.date}</div>
              </div>
              <div className="form-group">
                <input
                  name="transaction"
                  value={formData.transaction}
                  onChange={handleChange}
                  type="number"
                  className={
                    'form-control' + (errors.transaction ? ' is-invalid' : '')
                  }
                  required
                />
                <div className="invalid-feedback">{errors.date}</div>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CorporateInvoicesPay
