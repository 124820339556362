export default function handleResponse(response) {
  if (response.ok) {
    if (response.status === 204) {
      return ''
    }
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.includes('application/json')) {
      return response.json()
    }
    return response.text()
  }
  throw response
}
