import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import Moment from 'react-moment'

export default function BlogPostsIndex() {
  const [token, setToken] = useContext(AuthContext)

  const [posts, setPosts] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch('/api/edge/admin/blog/posts', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setPosts(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [token, setToken, setPosts, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Posts</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}
        <p className="controls">
          <Link to="/blog/posts/create" className="btn btn-primary">
            Create
          </Link>
        </p>

        {posts ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Title</th>
                <th>Slug</th>
                <th>Publish</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {posts.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Link to={'/blog/posts/' + item.id}>{item.title}</Link>
                  </td>
                  <td>{item.slug}</td>
                  <td>
                    {item.publish_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {item.publish_date}
                      </Moment>
                    ) : null}
                  </td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  )
}
