import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../context/auth/AuthContext'
import handleResponse from '../../handleResponse'
import parseErrorMessage from '../../parseErrorMessage'
import parseValidationErrors from '../../parseValidationErrors'
import PropTypes from 'prop-types'

const EditForm = ({ id, onSuccess, onCancel }) => {
  const [token, setToken] = useContext(AuthContext)

  const [formData, setFormData] = useState({
    text: '',
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    setLoading(true)
    fetch('/api/edge/admin/comments/' + id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setLoading(false)
        setFormData({
          text: data.source,
        })
      })
      .catch((error) => {
        setLoading(false)
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [token, setToken, setLoading, setFormData, setError, setErrors])

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    fetch('/api/edge/admin/comments/' + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(formData),
    })
      .then(handleResponse)
      .then(() => {
        setFormData({
          text: '',
        })
        onSuccess()
      })
      .catch(async (error) => {
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <div className="edit-form-block">
        {error ? <p className="alert alert-danger">{error}</p> : null}
        {!loading ? (
          <div>
            <div className="comment-form">
              <form method="POST" className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <textarea
                    name="text"
                    value={formData.text}
                    onChange={handleChange}
                    className={
                      'form-control' + (errors.text ? ' is-invalid' : '')
                    }
                    rows="10"
                    placeholder=""
                    required
                  />
                  <div className="invalid-feedback">{errors.text}</div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary">
                    Сохранить
                  </button>
                  <button
                    type="reset"
                    className="btn btn-primary"
                    onClick={onCancel}
                  >
                    Отмена
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div>...</div>
        )}
      </div>
    </div>
  )
}

EditForm.propTypes = {
  id: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default EditForm
