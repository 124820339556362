import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Not Found</li>
        </ol>
      </nav>
      <div className="container-fluid">
        <p className="alert alert-danger">Not Found</p>
      </div>
    </div>
  )
}

export default NotFound
