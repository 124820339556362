import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import parseValidationErrors from '../../../parseValidationErrors'

export default function AuthorsAuthorEdit() {
  const [token, setToken] = useContext(AuthContext)
  const params = useParams()

  const [author, setAuthor] = useState(null)

  const [formData, setFormData] = useState({
    nameFirst: '',
    nameLast: '',
    email: '',
    site: '',
  })

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  useEffect(() => {
    fetch('/api/edge/admin/authors/' + params.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setAuthor(data)
        setFormData({
          nameFirst: data.name.first,
          nameLast: data.name.last,
          email: data.email,
          site: data.site,
        })
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [token, setToken, setAuthor, setError])

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('/api/edge/admin/authors/' + author.id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(formData),
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch((error) => {
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/authors">Authors</Link>
          </li>
          {author ? (
            <>
              <li className="breadcrumb-item">
                <Link to={'/authors/' + author.id}>
                  {author.name.first} {author.name.last}
                </Link>
              </li>
              <li className="breadcrumb-item active">Edit</li>
            </>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {author ? (
          <div>
            <form method="post" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  name="nameFirst"
                  value={formData.nameFirst}
                  onChange={handleChange}
                  type="text"
                  className={
                    'form-control' + (errors.nameFirst ? ' is-invalid' : '')
                  }
                  placeholder="Name First"
                  required
                />
                <div className="invalid-feedback">{errors.nameFirst}</div>
              </div>
              <div className="form-group">
                <input
                  name="nameLast"
                  value={formData.nameLast}
                  onChange={handleChange}
                  type="text"
                  className={
                    'form-control' + (errors.nameLast ? ' is-invalid' : '')
                  }
                  placeholder="Name Last"
                  required
                />
                <div className="invalid-feedback">{errors.nameLast}</div>
              </div>
              <div className="form-group">
                <input
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  type="email"
                  className={
                    'form-control' + (errors.email ? ' is-invalid' : '')
                  }
                  placeholder="Email"
                />
                <div className="invalid-feedback">{errors.email}</div>
              </div>
              <div className="form-group">
                <input
                  name="site"
                  value={formData.site}
                  onChange={handleChange}
                  type="text"
                  className={
                    'form-control' + (errors.site ? ' is-invalid' : '')
                  }
                  placeholder="Site"
                />
                <div className="invalid-feedback">{errors.site}</div>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  )
}
