import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../../../context/auth/AuthContext'
import handleResponse from '../../../../../../handleResponse'
import parseErrorMessage from '../../../../../../parseErrorMessage'
import parseValidationErrors from '../../../../../../parseValidationErrors'

export default function EduSeriesEpisodesMarkUpdated() {
  const [token, setToken] = useContext(AuthContext)
  const params = useParams()

  const [series, setSeries] = useState(null)
  const [episode, setEpisode] = useState(null)

  const [formData, setFormData] = useState({
    date: new Date().toISOString().slice(0, 10),
    time: new Date().toISOString().slice(11, 16),
  })

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  const loadEpisode = () => {
    fetch(
      '/api/edge/admin/edu/series/' +
        params.series +
        '/episodes/' +
        params.episode,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then(handleResponse)
      .then((data) => {
        setEpisode(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }

  useEffect(() => {
    fetch('/api/edge/admin/edu/series/' + params.series, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setSeries(data)
        loadEpisode()
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [params, setSeries, setEpisode, token, setToken, setError])

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch(
      '/api/edge/admin/edu/series/' +
        series.id +
        '/episodes/' +
        episode.id +
        '/mark-updated',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          date: formData.date + ' ' + formData.time,
        }),
      },
    )
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch((error) => {
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/edu/series">Series</Link>
          </li>
          {series ? (
            <>
              <li className="breadcrumb-item">
                <Link to={'/edu/series/' + series.id}>
                  {series.content.title}
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={'/edu/series/' + series.id + '/episodes'}>
                  Episodes
                </Link>
              </li>
              {episode ? (
                <>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        '/edu/series/' + series.id + '/episodes/' + episode.id
                      }
                    >
                      {episode.content.title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Mark Updated</li>
                </>
              ) : null}
            </>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {episode ? (
          <div>
            <form method="post" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  type="date"
                  className={
                    'form-control' + (errors.date ? ' is-invalid' : '')
                  }
                  required
                />
                <div className="invalid-feedback">{errors.date}</div>
              </div>
              <div className="form-group">
                <input
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  type="time"
                  className={
                    'form-control' + (errors.date ? ' is-invalid' : '')
                  }
                  required
                />
                <div className="invalid-feedback">{errors.date}</div>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  )
}
