import React from 'react'
import LoginForm from '../components/LoginForm'

export default function Login() {
  return (
    <div className="app flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card-group">
              <div className="card p-4">
                <div className="card-body">
                  <LoginForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
