import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import Moment from 'react-moment'

export default function UsersShow() {
  const { id } = useParams()

  const [token, setToken] = useContext(AuthContext)
  const navigate = useNavigate()

  const [user, setUser] = useState(null)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch(`/api/edge/admin/users/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setUser(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [id, setUser, token, setToken, setError])

  function activate() {
    if (!window.confirm('Activate?')) {
      return
    }
    setError(null)
    fetch(`/api/edge/admin/users/${id}/activate`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setUser({
          ...user,
          status: 'active',
        })
        setSuccess('Activated.')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  function reactivate() {
    if (!window.confirm('Reactivate?')) {
      return
    }
    setError(null)
    fetch(`/api/edge/admin/users/${id}/reactivate`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setUser({
          ...user,
          status: 'wait',
        })
        setSuccess('Reactivation is Sent.')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  function remove() {
    if (!window.confirm('Delete?')) {
      return
    }
    setError(null)
    fetch(`/api/edge/admin/users/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(() => {
        navigate('/users')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/users">Users</Link>
          </li>
          {user ? <li className="breadcrumb-item active">{user.id}</li> : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {user ? (
          <div>
            <p className="controls">
              {user.status !== 'active' ? (
                <button className="btn btn-success" onClick={activate}>
                  Activate
                </button>
              ) : null}
              {user.status === 'wait' ? (
                <button className="btn btn-primary" onClick={reactivate}>
                  Resend Activation
                </button>
              ) : null}
              {user.status === 'wait' ? (
                <button className="btn btn-danger" onClick={remove}>
                  Delete
                </button>
              ) : null}
            </p>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{user.id}</td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {user.create_date}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <th>Role</th>
                  <td>{user.role}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{user.status}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}
