import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../context/auth/AuthContext'
import handleResponse from '../../../../handleResponse'
import parseErrorMessage from '../../../../parseErrorMessage'
import Moment from 'react-moment'

export default function PaymentAccountsShow() {
  const params = useParams()

  const [token, setToken] = useContext(AuthContext)

  const [account, setAccount] = useState(null)
  const [subscriptions, setSubscriptions] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch('/api/edge/admin/payment/accounts/' + params.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setAccount(data)
        fetch(
          '/api/edge/admin/payment/accounts/' + params.id + '/subscriptions',
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + token,
            },
          },
        )
          .then(handleResponse)
          .then((data) => {
            setSubscriptions(data)
          })
          .catch((error) => {
            setError(parseErrorMessage(error))
          })
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [params, setAccount, setSubscriptions, token, setToken, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">Accounts</li>
          {account ? (
            <li className="breadcrumb-item active">{account.email}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {account ? (
          <div>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Email</th>
                  <td>{account.email}</td>
                </tr>
                <tr>
                  <th>Active</th>
                  <td>{account.active ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <th>ID</th>
                  <td>{account.id}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
        {subscriptions ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Subscription</th>
                <th>Order</th>
                <th>Activate</th>
                <th>Pays</th>
                <th title="Other Subscriptions">Subs</th>
                <th title="Without Post Recipes">WP</th>
                <th>External ID</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((subscription) => (
                <tr key={subscription.id}>
                  <td>
                    <Link to={'/payment/subscriptions/' + subscription.id}>
                      {subscription.id.split('-')[0]}...
                    </Link>
                  </td>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {subscription.date}
                    </Moment>
                  </td>
                  <td>
                    {subscription.activate_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {subscription.activate_date}
                      </Moment>
                    ) : null}
                  </td>
                  <td>
                    {subscription.payments_count > 0
                      ? subscription.payments_count
                      : ''}
                  </td>
                  <td>
                    {subscription.account_other_subscriptions_count ? (
                      <span>
                        {subscription.account_other_subscriptions_count}
                      </span>
                    ) : null}
                  </td>
                  <td>
                    {subscription.without_post_receipts_count ? (
                      <span>{subscription.without_post_receipts_count}</span>
                    ) : null}
                  </td>
                  <td>
                    {subscription.external_id
                      ? subscription.external_id.slice(1, 10) + '...'
                      : ''}
                  </td>
                  <td>{subscription.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  )
}
