import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../context/auth/AuthContext'
import handleResponse from '../../../../handleResponse'
import parseErrorMessage from '../../../../parseErrorMessage'
import parseValidationErrors from '../../../../parseValidationErrors'

function ProductContent() {
  const [token, setToken] = useContext(AuthContext)
  const params = useParams()

  const [product, setProduct] = useState(null)

  const [formData, setFormData] = useState({
    content: '',
  })

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  useEffect(() => {
    fetch('/api/edge/admin/product/products/' + params.product, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setProduct(data)
        setFormData({
          content: data.content,
        })
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [token, setToken, setProduct, setError])

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('/api/edge/admin/product/products/' + product.id + '/content', {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(formData),
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch((error) => {
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/product/products">Products</Link>
          </li>
          {product ? (
            <>
              <li className="breadcrumb-item">
                <Link to={'/product/products/' + product.id}>
                  {product.title}
                </Link>
              </li>
              <li className="breadcrumb-item active">Content</li>
            </>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {product ? (
          <div>
            <form method="product" onSubmit={handleSubmit}>
              <div className="form-group">
                <textarea
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  className={
                    'form-control' + (errors.content ? ' is-invalid' : '')
                  }
                  placeholder="Content"
                  rows="20"
                />
                <div className="invalid-feedback">{errors.content}</div>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ProductContent
