import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'

export default function AuthorsAuthorShow() {
  const { id } = useParams()

  const [token, setToken] = useContext(AuthContext)

  const [author, setAuthor] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch(`/api/edge/admin/authors/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setAuthor(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [id, setAuthor, token, setToken, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/authors">Authors</Link>
          </li>
          {author ? (
            <li className="breadcrumb-item active">{author.id}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}
        {author ? (
          <div>
            <p className="controls">
              <Link
                to={'/authors/' + author.id + '/edit'}
                className="btn btn-primary"
              >
                Edit
              </Link>
            </p>
            <div>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{author.id}</td>
                  </tr>
                  <tr>
                    <th>Photo</th>
                    <td>
                      <img src={author.photo} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>
                      {author.name.first} {author.name.last}
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{author.email}</td>
                  </tr>
                  <tr>
                    <th>Site</th>
                    <td>{author.site}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
