import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../context/auth/AuthContext'
import handleResponse from '../../../../handleResponse'
import parseErrorMessage from '../../../../parseErrorMessage'
import Moment from 'react-moment'

export default function EduSeriesShow() {
  const params = useParams()

  const [token, setToken] = useContext(AuthContext)

  const [series, setSeries] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch('/api/edge/admin/edu/series/' + params.series, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setSeries(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [params, setSeries, token, setToken, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/edu/series">Series</Link>
          </li>
          {series ? (
            <li className="breadcrumb-item active">{series.title}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {series ? (
          <div>
            <p className="controls">
              <Link
                to={'/edu/series/' + series.id + '/edit'}
                className="btn btn-primary"
              >
                Edit
              </Link>
              <Link
                to={'/edu/series/' + series.id + '/episodes'}
                className="btn btn-primary"
              >
                Episodes
              </Link>
            </p>

            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Slug</th>
                  <td>{series.slug}</td>
                </tr>
                <tr>
                  <th>Sort</th>
                  <td>{series.sort}</td>
                </tr>
                <tr>
                  <th>Last Publish Date</th>
                  <td>
                    {series.last_publish_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {series.last_publish_date}
                      </Moment>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Last Update Date</th>
                  <td>
                    {series.last_update_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {series.last_update_date}
                      </Moment>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Title</th>
                  <td>{series.content.title}</td>
                </tr>
                <tr>
                  <th>Thumbnail</th>
                  <td>
                    {series.content.thumbnail ? (
                      <img
                        src={series.content.thumbnail}
                        style={{ maxWidth: '320px' }}
                        alt=""
                      />
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Poster</th>
                  <td>
                    {series.content.poster ? (
                      <img
                        src={series.content.poster}
                        style={{ maxWidth: '320px' }}
                        alt=""
                      />
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Meta Title</th>
                  <td>{series.meta.title}</td>
                </tr>
                <tr>
                  <th>Meta Description</th>
                  <td>{series.meta.description}</td>
                </tr>
              </tbody>
            </table>
            <div className="card">
              <div className="card-body">{series.content.short}</div>
            </div>
            <div className="card">
              <div className="card-body">{series.content.description}</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
