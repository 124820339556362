import React from 'react'
import './index.scss'
import * as serviceWorker from './serviceWorker'
import App from './App'
import AuthProvider from './context/auth/AuthProvider'
import { createRoot } from 'react-dom/client'

const root = createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
)

serviceWorker.unregister()
