import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../context/auth/AuthContext'
import handleResponse from '../../handleResponse'
import parseErrorMessage from '../../parseErrorMessage'
import Pagination from '../../components/Pagination'
import Moment from 'react-moment'
import usePage from '../../usePage'
import useQuery from '../../useQuery'

export default function UsersIndex() {
  const [token, setToken] = useContext(AuthContext)

  const query = useQuery()

  const [searchFormData, setSearchFormData] = useState({
    email: query.get('email') || '',
  })
  const [users, setUsers] = useState(null)
  const [pagination, setPagination] = useState(null)
  const [error, setError] = useState(null)

  const page = usePage()

  const handleSearchChange = (event) => {
    setSearchFormData({
      ...searchFormData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  useEffect(() => {
    fetch(
      `/api/edge/admin/users?search[email]=${searchFormData.email}&page=${page}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then(handleResponse)
      .then((data) => {
        setUsers(data.items)
        setPagination(data.pagination)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [page, searchFormData, token, setToken, setUsers, setPagination, setError])

  return (
    <div>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Users</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}
        <div className="card">
          <div className="card-body">
            <form className="form-inline">
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  value={searchFormData.email}
                  onChange={handleSearchChange}
                  className="form-control"
                  id="searchEmail"
                  placeholder="Email"
                />
              </div>
            </form>
          </div>
        </div>
        {users ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Email</th>
                <th>Network</th>
                <th>Role</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {user.create_date}
                    </Moment>
                  </td>
                  <td>
                    <Link to={'/users/' + user.id}>{user.email}</Link>
                  </td>
                  <td>{user.network ? user.network : null}</td>
                  <td>{user.role}</td>
                  <td>{user.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {pagination ? (
          <Pagination
            page={page}
            totalRows={pagination.total}
            perPage={pagination.per_page}
          />
        ) : null}
      </div>
    </div>
  )
}
