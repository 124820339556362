import AuthContext from '../../../context/auth/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import styles from './Subscriptions.module.scss'

export default function StatPaymentSubscriptions() {
  const [token, setToken] = useContext(AuthContext)
  const [items, setItems] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (items === null) {
      fetch('/api/edge/admin/payment/stat/subscriptions', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(handleResponse)
        .then((items) => setItems(items))
        .catch((error) => {
          if (error.status === 401) {
            setToken(null)
          }
          setError(parseErrorMessage(error))
        })
    }
  }, [items, token, setToken, setError])

  if (error) {
    return <p className="alert alert-danger">{error}</p>
  }

  if (items === null) {
    return null
  }

  return (
    <div className="card">
      <div className="card-header">Подписки</div>
      <div className={styles.body}>
        <div className={styles.overflow}>
          <table className={'table table-bordered m-0 ' + styles.daily}>
            <thead>
              <tr>
                {items.map((item) => (
                  <th key={'subscriptions-day' + item.day} title={item.day}>
                    {item.day.split('-').slice(-1).join()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {items.map((item) => (
                  <td key={'subscriptions-total' + item.day} title="Клиентов">
                    {item.total > 0 ? <span>{item.total}</span> : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td key={'subscriptions-active' + item.day} title="Активные">
                    {item.active > 0 ? <span>{item.active}</span> : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td
                    key={'subscriptions-subscribed' + item.day}
                    title="Новые подписки"
                  >
                    {item.subscribed > 0 ? (
                      <span>+{item.subscribed}</span>
                    ) : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td
                    key={'subscriptions-paid_once' + item.day}
                    title="Одноразовые"
                  >
                    {item.paid_once > 0 ? <span>+{item.paid_once}</span> : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td
                    key={'subscriptions-without_subscription' + item.day}
                    title="Без подписки"
                  >
                    {item.without_subscription > 0 ? (
                      <span>+{item.without_subscription}</span>
                    ) : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td
                    key={'subscriptions-cancelled' + item.day}
                    title="Отменённые подписки"
                  >
                    {item.cancelled > 0 ? <span>-{item.cancelled}</span> : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td
                    key={'subscriptions-rejected' + item.day}
                    title="Отклонённые подписки"
                  >
                    {item.rejected > 0 ? <span>-{item.rejected}</span> : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td
                    key={'subscriptions-all_payments' + item.day}
                    title="Новые/Все платежи"
                  >
                    {item.all_payments > 0 ? (
                      <span>
                        {item.new_payments}/{item.all_payments}
                      </span>
                    ) : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td key={'subscriptions-sum' + item.day} title="Сумма">
                    {item.all_sum > 0 ? (
                      <span>{(item.all_sum / 1000).toFixed(1)}</span>
                    ) : null}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
