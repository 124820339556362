import React from 'react'
import { Route, Routes as ReactRoutes } from 'react-router-dom'
import './Layout.scss'
import Home from '../pages/Home'
import UsersIndex from '../pages/users/Index'
import UsersShow from '../pages/users/_id/Show'
import BlogPostsIndex from '../pages/blog/posts/Index'
import BlogPostsCreate from '../pages/blog/posts/Create'
import BlogPostsPostShow from '../pages/blog/posts/_post/Show'
import BlogPostsPostPublish from '../pages/blog/posts/_post/Publish'
import BlogPostsPostChangeDate from '../pages/blog/posts/_post/ChangeDate'
import BlogPostsPostEdit from '../pages/blog/posts/_post/Edit'
import QnAQuestionsIndex from '../pages/qna/questions/Index'
import QnAQuestionsCreate from '../pages/qna/questions/Create'
import QnAQuestionsQuestionShow from '../pages/qna/questions/_question/Show'
import QnAQuestionsQuestionPublish from '../pages/qna/questions/_question/Publish'
import QnAQuestionsQuestionChangeDate from '../pages/qna/questions/_question/ChangeDate'
import QnAQuestionsQuestionEdit from '../pages/qna/questions/_question/Edit'
import QnAQuestionsQuestionAnswerAdd from '../pages/qna/questions/_question/answers/Add'
import QnAQuestionsQuestionAnswerEdit from '../pages/qna/questions/_question/answers/_answer/Edit'
import NotFound from './NotFound'
import CommentsIndex from '../pages/comments/Index'
import EduMembersIndex from '../pages/edu/members/Index'
import EduGroupsIndex from '../pages/edu/groups/Index'
import EduGroupsGroupShow from '../pages/edu/groups/_group/Show'
import EduGroupsCreate from '../pages/edu/groups/Create'
import EduGroupsGroupTagsTagShow from '../pages/edu/groups/_group/tags/_tag/Show'
import EduGroupsGroupTagsTagEdit from '../pages/edu/groups/_group/tags/_tag/Edit'
import PaymentPaymentsIndex from '../pages/payment/payments/Index'
import PaymentAccountsShow from '../pages/payment/accounts/_id/Show'
import PaymentSubscriptionsIndex from '../pages/payment/subscriptions/Index'
import PaymentSubscriptionsShow from '../pages/payment/subscriptions/_id/Show'
import EduSeriesIndex from '../pages/edu/series/Index'
import EduSeriesCreate from '../pages/edu/series/Create'
import EduSeriesShow from '../pages/edu/series/_series/Show'
import EduSeriesEdit from '../pages/edu/series/_series/Edit'
import EduSeriesEpisodesIndex from '../pages/edu/series/_series/episodes/Index'
import EduSeriesEpisodesCreate from '../pages/edu/series/_series/episodes/Create'
import EduSeriesEpisodesShow from '../pages/edu/series/_series/episodes/_episode/Show'
import EduSeriesEpisodesEdit from '../pages/edu/series/_series/episodes/_episode/Edit'
import EduSeriesEpisodesPublish from '../pages/edu/series/_series/episodes/_episode/Publish'
import EduSeriesEpisodesChangeDate from '../pages/edu/series/_series/episodes/_episode/ChangeDate'
import EduSeriesEpisodesSoon from '../pages/edu/series/_series/episodes/_episode/Soon'
import EduSeriesEpisodesMarkUpdated from '../pages/edu/series/_series/episodes/_episode/MarkUpdated'
import EduSeriesEpisodesDemoVideo from '../pages/edu/series/_series/episodes/_episode/DemoVideo'
import AuthorsIndex from '../pages/authors/Index'
import AuthorsCreate from '../pages/authors/Create'
import AuthorsAuthorEdit from '../pages/authors/_id/Edit'
import AuthorsAuthorShow from '../pages/authors/_id/Show'
import CorporateCompaniesIndex from '../pages/corporate/companies/Index'
import CorporateInvoicesIndex from '../pages/corporate/invoices/Index'
import CorporateActsIndex from '../pages/corporate/acts/Index'
import ProductsIndex from '../pages/product/products/Index'
import ProductCreate from '../pages/product/products/Create'
import ProductShow from '../pages/product/products/_product/Show'
import ProductEdit from '../pages/product/products/_product/Edit'
import ProductClose from '../pages/product/products/_product/Close'
import PaymentOrdersShow from '../pages/payment/orders/_id/Show'
import PaymentOrdersIndex from '../pages/payment/orders/Index'
import CorporateInvoicesPay from '../pages/corporate/invoices/_id/Pay'
import ProductContent from '../pages/product/products/_product/Content'

const Routes = () => (
  <ReactRoutes>
    <Route exact path="/" element={<Home />} />
    <Route exact path="/users" element={<UsersIndex />} />
    <Route exact path="/users/:id" element={<UsersShow />} />
    <Route exact path="/comments" element={<CommentsIndex />} />
    <Route
      exact
      path="/payment/accounts/:id"
      element={<PaymentAccountsShow />}
    />
    <Route exact path="/payment/payments" element={<PaymentPaymentsIndex />} />

    <Route
      exact
      path="/payment/subscriptions"
      element={<PaymentSubscriptionsIndex />}
    />
    <Route
      exact
      path="/payment/subscriptions/:id"
      element={<PaymentSubscriptionsShow />}
    />

    <Route exact path="/payment/orders" element={<PaymentOrdersIndex />} />
    <Route exact path="/payment/orders/:id" element={<PaymentOrdersShow />} />

    <Route
      exact
      path="/corporate/invoices"
      element={<CorporateInvoicesIndex />}
    />
    <Route
      exact
      path="/corporate/invoices/:id/pay"
      element={<CorporateInvoicesPay />}
    />
    <Route exact path="/corporate/acts" element={<CorporateActsIndex />} />
    <Route
      exact
      path="/corporate/companies"
      element={<CorporateCompaniesIndex />}
    />

    <Route exact path="/authors" element={<AuthorsIndex />} />
    <Route exact path="/authors/create" element={<AuthorsCreate />} />
    <Route exact path="/authors/:id" element={<AuthorsAuthorShow />} />
    <Route exact path="/authors/:id/edit" element={<AuthorsAuthorEdit />} />

    <Route exact path="/blog/posts" element={<BlogPostsIndex />} />
    <Route exact path="/blog/posts/create" element={<BlogPostsCreate />} />
    <Route exact path="/blog/posts/:post" element={<BlogPostsPostShow />} />

    <Route
      exact
      path="/blog/posts/:post/edit"
      element={<BlogPostsPostEdit />}
    />
    <Route
      exact
      path="/blog/posts/:post/publish"
      element={<BlogPostsPostPublish />}
    />
    <Route
      exact
      path="/blog/posts/:post/change-date"
      element={<BlogPostsPostChangeDate />}
    />

    <Route exact path="/qna/questions" element={<QnAQuestionsIndex />} />
    <Route
      exact
      path="/qna/questions/create"
      element={<QnAQuestionsCreate />}
    />
    <Route
      exact
      path="/qna/questions/:question"
      element={<QnAQuestionsQuestionShow />}
    />

    <Route
      exact
      path="/qna/questions/:question/edit"
      element={<QnAQuestionsQuestionEdit />}
    />
    <Route
      exact
      path="/qna/questions/:question/publish"
      element={<QnAQuestionsQuestionPublish />}
    />
    <Route
      exact
      path="/qna/questions/:question/change-date"
      element={<QnAQuestionsQuestionChangeDate />}
    />
    <Route
      exact
      path="/qna/questions/:question/answers/add"
      element={<QnAQuestionsQuestionAnswerAdd />}
    />
    <Route
      exact
      path="/qna/questions/:question/answers/:answer/edit"
      element={<QnAQuestionsQuestionAnswerEdit />}
    />

    <Route exact path="/product/products" element={<ProductsIndex />} />
    <Route exact path="/product/products/create" element={<ProductCreate />} />
    <Route exact path="/product/products/:product" element={<ProductShow />} />

    <Route
      exact
      path="/product/products/:product/edit"
      element={<ProductEdit />}
    />
    <Route
      exact
      path="/product/products/:product/close"
      element={<ProductClose />}
    />
    <Route
      exact
      path="/product/products/:product/content"
      element={<ProductContent />}
    />

    <Route exact path="/edu/members" element={<EduMembersIndex />} />
    <Route exact path="/edu/series" element={<EduSeriesIndex />} />
    <Route exact path="/edu/series/create" element={<EduSeriesCreate />} />
    <Route exact path="/edu/series/:series" element={<EduSeriesShow />} />
    <Route exact path="/edu/series/:series/edit" element={<EduSeriesEdit />} />
    <Route
      exact
      path="/edu/series/:series/episodes"
      element={<EduSeriesEpisodesIndex />}
    />
    <Route
      exact
      path="/edu/series/:series/episodes/create"
      element={<EduSeriesEpisodesCreate />}
    />
    <Route
      exact
      path="/edu/series/:series/episodes/:episode"
      element={<EduSeriesEpisodesShow />}
    />
    <Route
      exact
      path="/edu/series/:series/episodes/:episode/edit"
      element={<EduSeriesEpisodesEdit />}
    />
    <Route
      exact
      path="/edu/series/:series/episodes/:episode/publish"
      element={<EduSeriesEpisodesPublish />}
    />
    <Route
      exact
      path="/edu/series/:series/episodes/:episode/soon"
      element={<EduSeriesEpisodesSoon />}
    />
    <Route
      exact
      path="/edu/series/:series/episodes/:episode/change-date"
      element={<EduSeriesEpisodesChangeDate />}
    />
    <Route
      exact
      path="/edu/series/:series/episodes/:episode/mark-updated"
      element={<EduSeriesEpisodesMarkUpdated />}
    />
    <Route
      exact
      path="/edu/series/:series/episodes/:episode/demo-video"
      element={<EduSeriesEpisodesDemoVideo />}
    />
    <Route exact path="/edu/groups" element={<EduGroupsIndex />} />
    <Route exact path="/edu/groups/create" element={<EduGroupsCreate />} />
    <Route exact path="/edu/groups/:group" element={<EduGroupsGroupShow />} />
    <Route
      exact
      path="/edu/groups/:group/tags/:tag"
      element={<EduGroupsGroupTagsTagShow />}
    />
    <Route
      exact
      path="/edu/groups/:group/tags/:tag/edit"
      element={<EduGroupsGroupTagsTagEdit />}
    />
    <Route path="*" element={<NotFound />} />
  </ReactRoutes>
)

export default Routes
