import React, { useContext, useState } from 'react'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import parseValidationErrors from '../../../parseValidationErrors'
import { Link } from 'react-router-dom'

export default function BlogPostsCreate() {
  const [token, setToken] = useContext(AuthContext)

  const [formData, setFormData] = useState({
    slug: '',
    title: '',
    short: '',
    text: '',
    photo: '',
    thumbnail: '',
    metaTitle: '',
    metaDescription: '',
  })

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('/api/edge/admin/blog/posts', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(formData),
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/blog/posts">Posts</Link>
          </li>
          <li className="breadcrumb-item active">Create</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        <form method="post" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              name="slug"
              value={formData.slug}
              onChange={handleChange}
              type="text"
              className={'form-control' + (errors.slug ? ' is-invalid' : '')}
              placeholder="Slug"
              required
            />
            <div className="invalid-feedback">{errors.slug}</div>
          </div>
          <div className="form-group">
            <input
              name="title"
              value={formData.title}
              onChange={handleChange}
              type="text"
              className={'form-control' + (errors.title ? ' is-invalid' : '')}
              placeholder="Title"
              required
            />
            <div className="invalid-feedback">{errors.title}</div>
          </div>
          <div className="form-group">
            <textarea
              name="short"
              value={formData.short}
              onChange={handleChange}
              className={'form-control' + (errors.short ? ' is-invalid' : '')}
              placeholder="Short"
            />
            <div className="invalid-feedback">{errors.short}</div>
          </div>
          <div className="form-group">
            <textarea
              name="text"
              value={formData.text}
              onChange={handleChange}
              className={'form-control' + (errors.text ? ' is-invalid' : '')}
              rows="10"
              placeholder="Text"
            />
            <div className="invalid-feedback">{errors.text}</div>
          </div>
          <div className="form-group">
            <input
              name="photo"
              value={formData.photo}
              onChange={handleChange}
              type="text"
              className={'form-control' + (errors.photo ? ' is-invalid' : '')}
              placeholder="Photo"
              required
            />
            <div className="invalid-feedback">{errors.photo}</div>
          </div>
          <div className="form-group">
            <input
              name="thumbnail"
              value={formData.thumbnail}
              onChange={handleChange}
              type="text"
              className={
                'form-control' + (errors.thumbnail ? ' is-invalid' : '')
              }
              placeholder="Thumbnail"
              required
            />
            <div className="invalid-feedback">{errors.thumbnail}</div>
          </div>
          <div className="form-group">
            <input
              name="metaTitle"
              value={formData.metaTitle}
              onChange={handleChange}
              type="text"
              className={
                'form-control' + (errors.metaTitle ? ' is-invalid' : '')
              }
              placeholder="Meta Title"
              required
            />
            <div className="invalid-feedback">{errors.metaTitle}</div>
          </div>
          <div className="form-group">
            <textarea
              name="metaDescription"
              value={formData.metaDescription}
              onChange={handleChange}
              className={
                'form-control' + (errors.metaDescription ? ' is-invalid' : '')
              }
              placeholder="Meta Description"
            />
            <div className="invalid-feedback">{errors.metaDescription}</div>
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}
