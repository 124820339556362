import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../../../context/auth/AuthContext'
import handleResponse from '../../../../../../handleResponse'
import parseErrorMessage from '../../../../../../parseErrorMessage'
import parseValidationErrors from '../../../../../../parseValidationErrors'

export default function EduSeriesEpisodesEdit() {
  const [token, setToken] = useContext(AuthContext)
  const params = useParams()

  const [series, setSeries] = useState(null)
  const [episode, setEpisode] = useState(null)
  const [groups, setGroups] = useState(null)
  const [authors, setAuthors] = useState(null)

  const [formData, setFormData] = useState({
    slug: '',
    sort: '',
    title: '',
    description: '',
    private: '',
    poster: '',
    thumbnail: '',
    video: '',
    free: false,
    promoted: false,
    tags: [],
    authors: [],
    metaTitle: '',
    metaDescription: '',
  })

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    const input = event.target
    setFormData({
      ...formData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    })
  }

  const handleTagsChange = (event) => {
    const id = event.target.value
    console.log(id)
    setFormData({
      ...formData,
      tags: !formData.tags.includes(id)
        ? [...formData.tags, id]
        : formData.tags.filter((tag) => id !== tag),
    })
  }

  const handleAuthorsChange = (event) => {
    const id = event.target.value
    console.log(id)
    setFormData({
      ...formData,
      authors: !formData.authors.includes(id)
        ? [...formData.authors, id]
        : formData.authors.filter((author) => id !== author),
    })
  }

  const loadGrups = () => {
    fetch('/api/edge/admin/edu/groups', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setGroups(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }

  const loadAuthors = () => {
    fetch('/api/edge/admin/authors', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setAuthors(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }

  const loadEpisode = () => {
    fetch(
      '/api/edge/admin/edu/series/' +
        params.series +
        '/episodes/' +
        params.episode,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then(handleResponse)
      .then((data) => {
        setEpisode(data)
        setFormData({
          slug: data.slug,
          sort: data.sort,
          title: data.content.title,
          short: data.content.short,
          description: data.content.description,
          private: data.content.private,
          poster: data.content.poster,
          thumbnail: data.content.thumbnail,
          video: data.video ? data.video.url : null,
          free: data.free,
          promoted: data.promoted,
          tags: data.tags.map((tag) => tag.id),
          authors: data.authors.map((author) => author.id),
          metaTitle: data.meta.title,
          metaDescription: data.meta.description,
        })
        loadGrups()
        loadAuthors()
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }

  useEffect(() => {
    fetch('/api/edge/admin/edu/series/' + params.series, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setSeries(data)
        loadEpisode()
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [params, setSeries, setEpisode, token, setToken, setError])

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch(
      '/api/edge/admin/edu/series/' + series.id + '/episodes/' + episode.id,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          ...formData,
          sort: parseInt(formData.sort),
          free: !!formData.free,
          promoted: !!formData.promoted,
        }),
      },
    )
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch((error) => {
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/edu/series">Series</Link>
          </li>
          {series ? (
            <>
              <li className="breadcrumb-item">
                <Link to={'/edu/series/' + series.id}>
                  {series.content.title}
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={'/edu/series/' + series.id + '/episodes'}>
                  Episodes
                </Link>
              </li>
              {episode ? (
                <>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        '/edu/series/' + series.id + '/episodes/' + episode.id
                      }
                    >
                      {episode.content.title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Edit</li>
                </>
              ) : null}
            </>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {episode ? (
          <div>
            <form method="post" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  name="slug"
                  value={formData.slug}
                  onChange={handleChange}
                  type="text"
                  className={
                    'form-control' + (errors.slug ? ' is-invalid' : '')
                  }
                  placeholder="Slug"
                  required
                />
                <div className="invalid-feedback">{errors.slug}</div>
              </div>
              <div className="form-group">
                <input
                  name="sort"
                  value={formData.sort}
                  onChange={handleChange}
                  type="number"
                  className={
                    'form-control' + (errors.sort ? ' is-invalid' : '')
                  }
                  placeholder="Sort"
                  required
                />
                <div className="invalid-feedback">{errors.sort}</div>
              </div>
              <div className="form-group">
                <input
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  type="text"
                  className={
                    'form-control' + (errors.title ? ' is-invalid' : '')
                  }
                  placeholder="Title"
                  required
                />
                <div className="invalid-feedback">{errors.title}</div>
              </div>
              <div className="form-group">
                <textarea
                  name="short"
                  value={formData.short}
                  onChange={handleChange}
                  className={
                    'form-control' + (errors.short ? ' is-invalid' : '')
                  }
                  placeholder="Short"
                />
                <div className="invalid-feedback">{errors.short}</div>
              </div>
              <div className="form-group">
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className={
                    'form-control' + (errors.description ? ' is-invalid' : '')
                  }
                  rows="10"
                  placeholder="Description"
                />
                <div className="invalid-feedback">{errors.description}</div>
              </div>
              <div className="form-group">
                <textarea
                  name="private"
                  value={formData.private}
                  onChange={handleChange}
                  className={
                    'form-control' + (errors.private ? ' is-invalid' : '')
                  }
                  rows="10"
                  placeholder="Private"
                />
                <div className="invalid-feedback">{errors.private}</div>
              </div>
              <div className="form-group">
                <input
                  name="video"
                  value={formData.video}
                  onChange={handleChange}
                  type="url"
                  className={
                    'form-control' + (errors.video ? ' is-invalid' : '')
                  }
                  placeholder="Video"
                />
                <div className="invalid-feedback">{errors.video}</div>
              </div>
              <div className="form-group">
                <input
                  name="poster"
                  value={formData.poster}
                  onChange={handleChange}
                  type="url"
                  className={
                    'form-control' + (errors.poster ? ' is-invalid' : '')
                  }
                  placeholder="Poster"
                />
                <div className="invalid-feedback">{errors.poster}</div>
              </div>
              <div className="form-group">
                <input
                  name="thumbnail"
                  value={formData.thumbnail}
                  onChange={handleChange}
                  type="url"
                  className={
                    'form-control' + (errors.thumbnail ? ' is-invalid' : '')
                  }
                  placeholder="Thumbnail"
                />
                <div className="invalid-feedback">{errors.thumbnail}</div>
              </div>
              <div className="form-group">
                <input
                  name="metaTitle"
                  value={formData.metaTitle}
                  onChange={handleChange}
                  type="text"
                  className={
                    'form-control' + (errors.metaTitle ? ' is-invalid' : '')
                  }
                  placeholder="Meta Title"
                />
                <div className="invalid-feedback">{errors.metaTitle}</div>
              </div>
              <div className="form-group">
                <textarea
                  name="metaDescription"
                  value={formData.metaDescription}
                  onChange={handleChange}
                  className={
                    'form-control' +
                    (errors.metaDescription ? ' is-invalid' : '')
                  }
                  placeholder="Meta Description"
                />
                <div className="invalid-feedback">{errors.metaDescription}</div>
              </div>
              <div className="form-group">
                <label>
                  <input
                    name="free"
                    value="1"
                    checked={formData.free}
                    onChange={handleChange}
                    type="checkbox"
                  />{' '}
                  Free
                </label>
              </div>
              <div className="form-group">
                <label>
                  <input
                    name="promoted"
                    value="1"
                    checked={formData.promoted}
                    onChange={handleChange}
                    type="checkbox"
                  />{' '}
                  Promoted
                </label>
              </div>
              {authors ? (
                <div className="form-group">
                  <p>Authors</p>
                  {authors.map((author) => (
                    <div key={author.id}>
                      <label>
                        <input
                          name="authors[]"
                          value={author.id}
                          onChange={handleAuthorsChange}
                          type="checkbox"
                          checked={formData.authors.includes(author.id)}
                        />{' '}
                        {author.name}
                      </label>
                    </div>
                  ))}
                </div>
              ) : null}
              {groups ? (
                <div className="form-group">
                  {groups.map((group) => (
                    <div key={group.id}>
                      <p>{group.name}</p>
                      {group.tags.map((tag) => (
                        <Fragment key={tag.id}>
                          <label>
                            <input
                              name="tags[]"
                              value={tag.id}
                              onChange={handleTagsChange}
                              type="checkbox"
                              checked={formData.tags.includes(tag.id)}
                            />{' '}
                            {tag.name}
                          </label>
                          <br />
                        </Fragment>
                      ))}
                    </div>
                  ))}
                </div>
              ) : null}
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  )
}
