import React, { useCallback, useState } from 'react'
import AuthContext from './AuthContext'
import PropTypes from 'prop-types'

export default function AuthProvider({ children }) {
  const [token, setStateToken] = useState(localStorage.getItem('access_token'))

  const setToken = useCallback(
    (token) => {
      localStorage.setItem('access_token', token)
      setStateToken(token)
    },
    [setStateToken],
  )

  return (
    <AuthContext.Provider value={[token, setToken]}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.object,
  initialToken: PropTypes.string,
}
