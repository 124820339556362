import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/auth/AuthContext'

const NavBar = () => {
  const [, setToken] = useContext(AuthContext)

  function logout(event) {
    event.preventDefault()
    setToken(null)
  }

  return (
    <nav className="sidebar-nav">
      <ul className="nav">
        <li className="nav-item">
          <Link className="nav-link" to="/">
            <i className="nav-icon cui-chart" /> Dashboard
          </Link>
        </li>
        <li className="nav-title">Common</li>
        <li className="nav-item">
          <Link className="nav-link" to="/users">
            <i className="nav-icon cui-star" /> Users
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/authors">
            <i className="nav-icon cui-star" /> Authors
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/comments">
            <i className="nav-icon cui-star" /> Comments
          </Link>
        </li>
        <li className="nav-title">Payments</li>
        <li className="nav-item">
          <Link className="nav-link" to="/payment/subscriptions">
            <i className="nav-icon cui-star" /> Subscriptions
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/payment/orders">
            <i className="nav-icon cui-star" /> Orders
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/payment/payments">
            <i className="nav-icon cui-star" /> Payments
          </Link>
        </li>
        <li className="nav-title">Corporate</li>
        <li className="nav-item">
          <Link className="nav-link" to="/corporate/invoices">
            <i className="nav-icon cui-star" /> Invoices
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/corporate/acts">
            <i className="nav-icon cui-star" /> Acts
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/corporate/companies">
            <i className="nav-icon cui-star" /> Companies
          </Link>
        </li>
        <li className="nav-title">Product</li>
        <li className="nav-item">
          <Link className="nav-link" to="/product/products">
            <i className="nav-icon cui-star" /> Products
          </Link>
        </li>
        <li className="nav-title">Blog</li>
        <li className="nav-item">
          <Link className="nav-link" to="/blog/posts">
            <i className="nav-icon cui-star" /> Posts
          </Link>
        </li>
        <li className="nav-title">QnA</li>
        <li className="nav-item">
          <Link className="nav-link" to="/qna/questions">
            <i className="nav-icon cui-star" /> Questions
          </Link>
        </li>
        <li className="nav-title">Edu</li>
        <li className="nav-item">
          <Link className="nav-link" to="/edu/members">
            <i className="nav-icon cui-star" /> Members
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/edu/series">
            <i className="nav-icon cui-star" /> Series
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/edu/groups">
            <i className="nav-icon cui-star" /> Groups
          </Link>
        </li>
        <li className="nav-title">Auth</li>
        <li className="nav-item">
          <a className="nav-link" href="" onClick={logout}>
            <i className="nav-icon cui-star" /> Log Out
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default NavBar
