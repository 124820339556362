import React, { useContext, useState } from 'react'
import AuthContext from '../context/auth/AuthContext'
import handleResponse from '../handleResponse'

const LoginForm = () => {
  const [, setToken] = useContext(AuthContext)

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const [error, setError] = useState(null)

  function performValidation() {
    return formData.email.length > 0 && formData.password.length > 0
  }

  function handleChange(event) {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  function handleSubmit(event) {
    event.preventDefault()

    fetch(process.env.REACT_APP_OAUTH_TOKEN_URL + '/token', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'password',
        username: formData.email,
        password: formData.password,
        client_id: 'frontend',
        client_secret: '',
      }),
    })
      .then(handleResponse)
      .then((result) => setToken(result.access_token))
      .catch(async (error) => {
        if (error.status === 400) {
          const data = await error.json()
          if (data.error === 'invalid_grant') {
            setError('Неверный Email или пароль')
          } else {
            setError(data.error ? data.error : 'Ошибка сервера')
          }
        } else {
          setError(JSON.stringify(error))
        }
      })
  }

  return (
    <div>
      <h1>Login</h1>
      <p className="text-muted">Sign In to your account</p>
      {error ? <div className="alert alert-danger">{error}</div> : null}
      <form method="post" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!performValidation()}
        >
          Submit
        </button>
      </form>
    </div>
  )
}

export default LoginForm
