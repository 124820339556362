import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../context/auth/AuthContext'
import handleResponse from '../../../../handleResponse'
import parseErrorMessage from '../../../../parseErrorMessage'
import Moment from 'react-moment'

function ProductShow() {
  const [token, setToken] = useContext(AuthContext)
  const params = useParams()

  const [product, setProduct] = useState(null)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch('/api/edge/admin/product/products/' + params.product, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setProduct(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [token, setToken, setProduct, setError])

  const publish = (event) => {
    event.preventDefault()
    if (!confirm('Publish?')) {
      return
    }
    fetch('/api/edge/admin/product/products/' + product.id + '/publish', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        fetch('/api/edge/admin/product/products/' + product.id, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(handleResponse)
          .then((data) => {
            setSuccess('Success!')
            setProduct(data)
          })
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const draft = (event) => {
    event.preventDefault()
    if (!confirm('Draft?')) {
      return
    }
    fetch('/api/edge/admin/product/products/' + product.id + '/draft', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        fetch('/api/edge/admin/product/products/' + product.id, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(handleResponse)
          .then((data) => {
            setSuccess('Success!')
            setProduct(data)
          })
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/product/products">Products</Link>
          </li>
          {product ? (
            <li className="breadcrumb-item active">{product.title}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {product ? (
          <div>
            <p className="controls">
              <Link
                to={'/product/products/' + product.id + '/edit'}
                className="btn btn-primary"
              >
                Edit
              </Link>
              <Link
                to={'/product/products/' + product.id + '/content'}
                className="btn btn-primary"
              >
                Content
              </Link>
              {!product.active ? (
                <button className="btn btn-success" onClick={publish}>
                  Publish
                </button>
              ) : null}
              {product.active ? (
                <button className="btn btn-primary" onClick={draft}>
                  Draft
                </button>
              ) : null}
              {product.active && !product.closed ? (
                <Link
                  to={'/product/products/' + product.id + '/close'}
                  className="btn btn-primary"
                >
                  Close
                </Link>
              ) : null}
            </p>

            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Slug</th>
                  <td>{product.slug}</td>
                </tr>
                <tr>
                  <th>Title</th>
                  <td>{product.title}</td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">{product.date}</Moment>
                  </td>
                </tr>
                <tr>
                  <th>Active</th>
                  <td>{product.active ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>{product.price.endPrice}</td>
                </tr>
                <tr>
                  <th>Price Start Date</th>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {product.price.startDate}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <th>Price End Date</th>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {product.price.endDate}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <th>Price Interval</th>
                  <td>{product.price.interval}</td>
                </tr>
                <tr>
                  <th>Price Increment</th>
                  <td>{product.price.increment}</td>
                </tr>
                <tr>
                  <th>Client Discount</th>
                  <td>{product.clientDiscount}</td>
                </tr>
                <tr>
                  <th>Closed</th>
                  <td>
                    {product.closeDate ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {product.closeDate}
                      </Moment>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ProductShow
