import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import Pagination from '../../../components/Pagination'
import Moment from 'react-moment'
import usePage from '../../../usePage'
import styles from './Index.module.scss'

export default function PaymentOrdersIndex() {
  const [token, setToken] = useContext(AuthContext)

  const [orders, setOrders] = useState(null)
  const [pagination, setPagination] = useState(null)
  const [error, setError] = useState(null)

  const page = usePage()

  useEffect(() => {
    fetch('/api/edge/admin/payment/orders?page=' + page, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setOrders(data.items)
        setPagination(data.pagination)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [page, token, setToken, setOrders, setPagination, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Orders</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {orders ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Order</th>
                  <th>Paid</th>
                  <th />
                  <th>Account</th>
                  <th style={{ textAlign: 'center' }} title="Other Orders">
                    O
                  </th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr
                    key={order.id}
                    className={
                      order.without_post_receipts_count
                        ? styles.wpr
                        : order.is_first
                          ? styles.first
                          : ''
                    }
                  >
                    <td>
                      <Link to={'/payment/orders/' + order.id}>
                        {order.id.split('-')[0]}...
                      </Link>
                    </td>
                    <td>
                      <Moment format="YYYY-MM-DD HH:mm:ss">{order.date}</Moment>
                    </td>
                    <td>
                      {order.pay_date ? (
                        <Moment format="YYYY-MM-DD HH:mm:ss">
                          {order.pay_date}
                        </Moment>
                      ) : null}
                    </td>
                    <td style={{ textAlign: 'center' }}>{order.amount}</td>
                    <td>
                      <Link to={'/payment/accounts/' + order.account.id}>
                        {order.account.email}
                      </Link>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {order.account_other_orders_count ? (
                        <span>{order.account_other_orders_count}</span>
                      ) : null}
                    </td>
                    <td>{order.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        {pagination ? (
          <Pagination
            page={page}
            totalRows={pagination.total}
            perPage={pagination.per_page}
          />
        ) : null}
      </div>
    </div>
  )
}
