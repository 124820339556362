import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import Moment from 'react-moment'

function ProductsIndex() {
  const [token, setToken] = useContext(AuthContext)

  const [products, setProducts] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch('/api/edge/admin/product/products', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setProducts(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [token, setToken, setProducts, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Products</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}
        <p className="controls">
          <Link to="/product/products/create" className="btn btn-primary">
            Create
          </Link>
        </p>

        {products ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Title</th>
                <th>Slug</th>
                <th>Price</th>
                <th>Active</th>
                <th>Closed</th>
              </tr>
            </thead>
            <tbody>
              {products.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">{item.date}</Moment>
                  </td>
                  <td>
                    <Link to={'/product/products/' + item.id}>
                      {item.title}
                    </Link>
                  </td>
                  <td>{item.slug}</td>
                  <td>{item.price}</td>
                  <td>{item.active ? 'Yes' : 'No'}</td>
                  <td>
                    {item.closeDate ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {item.closeDate}
                      </Moment>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  )
}

export default ProductsIndex
