import AuthContext from '../../../context/auth/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import styles from './Orders.module.scss'

export default function StatPaymentOrders() {
  const [token, setToken] = useContext(AuthContext)
  const [items, setItems] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (items === null) {
      fetch('/api/edge/admin/payment/stat/orders', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(handleResponse)
        .then((items) => setItems(items))
        .catch((error) => {
          if (error.status === 401) {
            setToken(null)
          }
          setError(parseErrorMessage(error))
        })
    }
  }, [items, token, setToken, setError])

  if (error) {
    return <p className="alert alert-danger">{error}</p>
  }

  if (items === null) {
    return null
  }

  return (
    <div className="card">
      <div className="card-header">Покупки</div>
      <div className={styles.body}>
        <div className={styles.overflow}>
          <table className={'table table-bordered m-0 ' + styles.daily}>
            <thead>
              <tr>
                {items.map((item) => (
                  <th key={'orders-day-' + item.day} title={item.day}>
                    {item.day.split('-').slice(-1).join()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {items.map((item) => (
                  <td key={'orders-total-' + item.day} title="Клиентов">
                    {item.total > 0 ? <span>{item.total}</span> : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td key={'orders-paid-' + item.day} title="Всего">
                    {item.paid > 0 ? <span>{item.paid}</span> : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td key={'orders-payments-' + item.day} title="Сегодня">
                    {item.payments > 0 ? <span>{item.payments}</span> : null}
                  </td>
                ))}
              </tr>
              <tr>
                {items.map((item) => (
                  <td key={'orders-sum-' + item.day} title="Сумма">
                    {item.sum > 0 ? (
                      <span>{(item.sum / 1000).toFixed(1)}</span>
                    ) : null}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
