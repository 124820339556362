import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import Pagination from '../../../components/Pagination'
import usePage from '../../../usePage'
import Moment from 'react-moment'

function CorporateInvoicesIndex() {
  const [token, setToken] = useContext(AuthContext)

  const [invoices, setPayments] = useState(null)
  const [pagination, setPagination] = useState(null)
  const [error, setError] = useState(null)

  const page = usePage()

  useEffect(() => {
    fetch('/api/edge/admin/corporate/invoices?page=' + page, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setPayments(data.items)
        setPagination(data.pagination)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [page, token, setToken, setPayments, setPagination, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Invoices</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {invoices ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Number</th>
                  <th>Company</th>
                  <th>Amount</th>
                  <th>Subject</th>
                  <th>Paid</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td>
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {invoice.date}
                      </Moment>
                    </td>
                    <td>
                      <a href={invoice.url} target="_blank" rel="noreferrer">
                        {invoice.number}
                      </a>
                    </td>
                    <td>{invoice.company.name}</td>
                    <td>{invoice.amount}</td>
                    <td>{invoice.subject}</td>
                    <td>
                      {invoice.paid ? (
                        <Moment format="YYYY-MM-DD HH:mm:ss">
                          {invoice.paid}
                        </Moment>
                      ) : (
                        <Link
                          to={'/corporate/invoices/' + invoice.id + '/pay'}
                          className="btn btn-primary"
                        >
                          Pay
                        </Link>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        {pagination ? (
          <Pagination
            page={page}
            totalRows={pagination.total}
            perPage={pagination.per_page}
          />
        ) : null}
      </div>
    </div>
  )
}

export default CorporateInvoicesIndex
