import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AuthContext from '../../../../context/auth/AuthContext'
import handleResponse from '../../../../handleResponse'
import parseErrorMessage from '../../../../parseErrorMessage'
import Moment from 'react-moment'

export default function QnAQuestionsQuestionShow() {
  const [token, setToken] = useContext(AuthContext)
  const params = useParams()
  const navigate = useNavigate()

  const [question, setQuestion] = useState(null)
  const [answers, setAnswers] = useState(null)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch('/api/edge/admin/qna/questions/' + params.question, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setQuestion(data)

        fetch('/api/edge/admin/qna/questions/' + params.question + '/answers', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(handleResponse)
          .then((data) => {
            setAnswers(data)
          })
          .catch((error) => {
            setError(parseErrorMessage(error))
          })
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [token, setToken, setQuestion, setError])

  const remove = (event) => {
    event.preventDefault()
    if (!confirm('Remove?')) {
      return
    }

    fetch('/api/edge/admin/qna/questions/' + question.id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        navigate('/qna/questions')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const draft = (event) => {
    event.preventDefault()
    if (!confirm('Draft?')) {
      return
    }
    fetch('/api/edge/admin/qna/questions/' + question.id + '/draft', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        fetch('/api/edge/admin/qna/questions/' + params.question, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(handleResponse)
          .then((data) => {
            setSuccess('Success!')
            setQuestion(data)
          })
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/qna/questions">Questions</Link>
          </li>
          {question ? (
            <li className="breadcrumb-item active">{question.content.title}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {question ? (
          <div>
            <p className="controls">
              <Link
                to={'/qna/questions/' + question.id + '/edit'}
                className="btn btn-primary"
              >
                Edit
              </Link>
              {!question.active ? (
                <Link
                  to={'/qna/questions/' + question.id + '/publish'}
                  className="btn btn-primary"
                >
                  Publish
                </Link>
              ) : null}
              {question.active ? (
                <button className="btn btn-primary" onClick={draft}>
                  Draft
                </button>
              ) : null}
              {question.active ? (
                <Link
                  to={'/qna/questions/' + question.id + '/change-date'}
                  className="btn btn-primary"
                >
                  Change Date
                </Link>
              ) : null}
              <button className="btn btn-danger" onClick={remove}>
                Delete
              </button>
            </p>

            <h3>Question</h3>
            <table className="table table-bordered mb-4">
              <tbody>
                <tr>
                  <th>Date</th>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {question.date}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <th>Slug</th>
                  <td>{question.slug}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{question.status}</td>
                </tr>
                <tr>
                  <th>Publish Date</th>
                  <td>
                    {question.publish_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {question.publish_date}
                      </Moment>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Title</th>
                  <td>{question.content.title}</td>
                </tr>
                <tr>
                  <th>Meta Title</th>
                  <td>{question.meta.title}</td>
                </tr>
                <tr>
                  <th>Meta Description</th>
                  <td>{question.meta.description}</td>
                </tr>
                <tr>
                  <th>Source</th>
                  <td>
                    <a
                      href={question.source}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      {question.source}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="card">
              <div
                className="card-body pb-0"
                dangerouslySetInnerHTML={{ __html: question.content.shortSafe }}
              ></div>
            </div>
            <div className="card">
              <div
                className="card-body pb-0"
                dangerouslySetInnerHTML={{ __html: question.content.textSafe }}
              ></div>
            </div>
            <h3>Answers</h3>
            {answers
              ? answers.map((answer) => (
                  <div key={answer.id} className="card">
                    <div>
                      <Link
                        to={
                          '/qna/questions/' +
                          question.id +
                          '/answers/' +
                          answer.id +
                          '/edit'
                        }
                        className="btn btn-primary"
                      >
                        Edit
                      </Link>
                    </div>
                    <div
                      className="card-body pb-0"
                      dangerouslySetInnerHTML={{ __html: answer.textSafe }}
                    ></div>
                  </div>
                ))
              : null}
            <p className="controls">
              <Link
                to={'/qna/questions/' + question.id + '/answers/add'}
                className="btn btn-primary"
              >
                Add Answer
              </Link>
            </p>
          </div>
        ) : null}
      </div>
    </div>
  )
}
