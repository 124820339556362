import React, { useContext } from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'

import Login from './pages/Login'
import Layout from './components/Layout'
import AuthContext from './context/auth/AuthContext'

export default function App() {
  const [token] = useContext(AuthContext)

  return <BrowserRouter>{token ? <Layout /> : <Login />}</BrowserRouter>
}
