import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import Pagination from '../../../components/Pagination'
import usePage from '../../../usePage'

function CorporateCompaniesIndex() {
  const [token, setToken] = useContext(AuthContext)

  const [companies, setPayments] = useState(null)
  const [pagination, setPagination] = useState(null)
  const [error, setError] = useState(null)

  const page = usePage()

  useEffect(() => {
    fetch('/api/edge/admin/corporate/companies?page=' + page, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setPayments(data.items)
        setPagination(data.pagination)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [page, token, setToken, setPayments, setPagination, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Companies</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {companies ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Owner</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company) => (
                  <tr key={company.id}>
                    <td>{company.name}</td>
                    <td>
                      <Link to={'/users/' + company.owner}>
                        {company.owner}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        {pagination ? (
          <Pagination
            page={page}
            totalRows={pagination.total}
            perPage={pagination.per_page}
          />
        ) : null}
      </div>
    </div>
  )
}

export default CorporateCompaniesIndex
