import React from 'react'
import StatUsers from '../components/Stat/Users/Users'
import StatPaymentSubscriptions from '../components/Stat/Payment/Subscriptions'
import StatPaymentOrders from '../components/Stat/Payment/Orders'

export default function Home() {
  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">Home</li>
        </ol>
      </nav>
      <div className="container-fluid">
        <StatUsers />
        <StatPaymentSubscriptions />
        <StatPaymentOrders />
      </div>
    </div>
  )
}
