import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../../context/auth/AuthContext'
import handleResponse from '../../../../handleResponse'
import parseErrorMessage from '../../../../parseErrorMessage'
import parseValidationErrors from '../../../../parseValidationErrors'
import { Link, useParams } from 'react-router-dom'

export default function EduGroupsGroupShow() {
  const [token, setToken] = useContext(AuthContext)
  const params = useParams()

  const [group, setGroup] = useState(null)
  const [success, setSuccess] = useState(null)
  const [errors, setErrors] = useState({})
  const [error, setError] = useState(null)

  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    sort: 0,
  })

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  const load = () => {
    fetch('/api/edge/admin/edu/groups/' + params.group, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setGroup(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }

  useEffect(() => {
    load()
  }, [token, setToken, setGroup, setError])

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('/api/edge/admin/edu/groups/' + group.id + '/tags', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(formData),
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
        setFormData({
          name: '',
          slug: '',
          sort: 0,
        })
        load()
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  const remove = (id, event) => {
    event.preventDefault()
    if (!confirm('Remove?')) {
      return
    }
    fetch('/api/edge/admin/edu/groups/' + group.id + '/tags/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
        load()
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/edu/groups">Groups</Link>
          </li>
          {group ? (
            <li className="breadcrumb-item active">{group.name}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {group ? (
          <div>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{group.name}</td>
                </tr>
                <tr>
                  <th>Sort</th>
                  <td>{group.sort}</td>
                </tr>
              </tbody>
            </table>

            <form method="post" onSubmit={handleSubmit}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Slug</th>
                    <th>Sort</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {group.tags.map((tag) => (
                    <tr key={tag.id}>
                      <td>
                        <Link
                          to={'/edu/groups/' + group.id + '/tags/' + tag.id}
                        >
                          {tag.name}
                        </Link>
                      </td>
                      <td>{tag.slug}</td>
                      <td>{tag.sort}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={(e) => remove(tag.id, e)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <div className="form-group mb-0">
                        <input
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          type="text"
                          className={
                            'form-control' + (errors.name ? ' is-invalid' : '')
                          }
                          required
                        />
                        <div className="invalid-feedback">{errors.name}</div>
                      </div>
                    </td>
                    <td>
                      <div className="form-group mb-0">
                        <input
                          name="slug"
                          value={formData.slug}
                          onChange={handleChange}
                          type="text"
                          className={
                            'form-control' + (errors.slug ? ' is-invalid' : '')
                          }
                          required
                        />
                        <div className="invalid-feedback">{errors.slug}</div>
                      </div>
                    </td>
                    <td>
                      <div className="form-group mb-0">
                        <input
                          name="sort"
                          value={formData.sort}
                          onChange={handleChange}
                          type="number"
                          className={
                            'form-control' + (errors.sort ? ' is-invalid' : '')
                          }
                          required
                        />
                        <div className="invalid-feedback">{errors.sort}</div>
                      </div>
                    </td>
                    <td>
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  )
}
