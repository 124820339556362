import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

const Pagination = ({ page = 1, totalRows, perPage }) => {
  const { pathname } = useLocation()
  const maxPage = Math.ceil(totalRows / perPage)

  return (
    <ul className="pagination b-pagination">
      {page > 1 ? (
        <li className="page-item" key="first">
          <Link to={pathname} className="page-link">
            «
          </Link>
        </li>
      ) : (
        <li className="page-item disabled" key="first">
          <span className="page-link">«</span>
        </li>
      )}

      {Array.from(Array(maxPage).keys())
        .map((i) => i + 1)
        .map((current) => (
          <li
            className={'page-item' + (current === page) ? ' active' : ''}
            key={current}
          >
            <Link
              to={{
                pathname,
                search: '?page=' + current,
              }}
              className="page-link"
            >
              {current}
            </Link>
          </li>
        ))}

      {page !== maxPage ? (
        <li className="page-item" key="last">
          <Link
            to={{
              pathname,
              search: `?page=${maxPage}`,
            }}
            className="page-link"
          >
            »
          </Link>
        </li>
      ) : (
        <li className="page-item disabled" key="last">
          <span className="page-link">»</span>
        </li>
      )}
    </ul>
  )
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
}

export default Pagination
