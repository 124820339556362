import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../../../context/auth/AuthContext'
import handleResponse from '../../../../../../handleResponse'
import parseErrorMessage from '../../../../../../parseErrorMessage'
import parseValidationErrors from '../../../../../../parseValidationErrors'

export default function EduGroupsGroupTagsTagEdit() {
  const [token, setToken] = useContext(AuthContext)
  const params = useParams()

  const [group, setGroup] = useState(null)
  const [tag, setTag] = useState(null)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    sort: 0,
  })

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  useEffect(() => {
    fetch(
      '/api/edge/admin/edu/groups/' + params.group + '/tags/' + params.tag,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then(handleResponse)
      .then((data) => {
        setGroup(data.group)
        setTag(data.tag)
        setFormData({
          name: data.tag.name,
          slug: data.tag.slug,
          sort: data.tag.sort,
        })
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [token, setToken, setGroup, setTag, setError])

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('/api/edge/admin/edu/groups/' + group.id + '/tags/' + tag.id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(formData),
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch((error) => {
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/edu/groups">Groups</Link>
          </li>
          {group ? (
            <li className="breadcrumb-item">
              <Link to={'/edu/groups/' + group.id}>{group.name}</Link>
            </li>
          ) : null}
          {tag ? (
            <li className="breadcrumb-item">
              <Link to={'/edu/groups/' + group.id + '/tags/' + tag.id}>
                {tag.name}
              </Link>
            </li>
          ) : null}
          <li className="breadcrumb-item active">Edit</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {tag ? (
          <div>
            <form method="post" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  type="text"
                  className={
                    'form-control' + (errors.name ? ' is-invalid' : '')
                  }
                  placeholder="Name"
                  required
                />
                <div className="invalid-feedback">{errors.name}</div>
              </div>
              <div className="form-group">
                <input
                  name="slug"
                  value={formData.slug}
                  onChange={handleChange}
                  type="text"
                  className={
                    'form-control' + (errors.slug ? ' is-invalid' : '')
                  }
                  placeholder="Slug"
                  required
                />
                <div className="invalid-feedback">{errors.slug}</div>
              </div>
              <div className="form-group">
                <input
                  name="sort"
                  value={formData.sort}
                  onChange={handleChange}
                  type="number"
                  className={
                    'form-control' + (errors.sort ? ' is-invalid' : '')
                  }
                  placeholder="Sort"
                  required
                />
                <div className="invalid-feedback">{errors.sort}</div>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  )
}
