import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../../../context/auth/AuthContext'
import handleResponse from '../../../../../../handleResponse'
import parseErrorMessage from '../../../../../../parseErrorMessage'
import Moment from 'react-moment'
import formatDuration from '../../../../../../fomatDuration'

export default function EduSeriesEpisodesShow() {
  const params = useParams()

  const [token, setToken] = useContext(AuthContext)

  const [series, setSeries] = useState(null)
  const [episode, setEpisode] = useState(null)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const loadEpisode = () => {
    fetch(
      '/api/edge/admin/edu/series/' +
        params.series +
        '/episodes/' +
        params.episode,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then(handleResponse)
      .then((data) => {
        setEpisode(data)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }

  useEffect(() => {
    fetch('/api/edge/admin/edu/series/' + params.series, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setSeries(data)
        loadEpisode()
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [params, setSeries, setEpisode, token, setToken, setError])

  const draft = () => {
    if (!confirm('Draft?')) {
      return
    }
    setError(null)
    fetch(
      '/api/edge/admin/edu/series/' +
        series.id +
        '/episodes/' +
        episode.id +
        '/draft',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then(() => {
        setSuccess('Drafted.')
        setEpisode({
          ...episode,
          status: 'draft',
        })
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const loadVideo = () => {
    setError(null)
    fetch(
      '/api/edge/admin/edu/series/' +
        series.id +
        '/episodes/' +
        episode.id +
        '/load-video',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then(() => {
        setSuccess('Video is loaded.')
        loadEpisode()
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/edu/series">Series</Link>
          </li>
          {series ? (
            <>
              <li className="breadcrumb-item">
                <Link to={'/edu/series/' + series.id}>
                  {series.content.title}
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={'/edu/series/' + series.id + '/episodes'}>
                  Episodes
                </Link>
              </li>
              {episode ? (
                <li className="breadcrumb-item active">
                  {episode.content.title}
                </li>
              ) : null}
            </>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {episode ? (
          <div>
            <p className="controls">
              <Link
                to={
                  '/edu/series/' +
                  series.id +
                  '/episodes/' +
                  episode.id +
                  '/edit'
                }
                className="btn btn-primary"
              >
                Edit
              </Link>
              {episode.status !== 'active' ? (
                <Link
                  to={
                    '/edu/series/' +
                    series.id +
                    '/episodes/' +
                    episode.id +
                    '/publish'
                  }
                  className="btn btn-primary"
                >
                  Publish
                </Link>
              ) : null}
              <Link
                v-if="episode.status !== 'soon'"
                to={
                  '/edu/series/' +
                  series.id +
                  '/episodes/' +
                  episode.id +
                  '/soon'
                }
                className="btn btn-primary"
              >
                Soon
              </Link>
              {episode.status !== 'draft' ? (
                <button className="btn btn-success" onClick={draft}>
                  Draft
                </button>
              ) : null}
              {episode.publish_date ? (
                <Link
                  to={
                    '/edu/series/' +
                    series.id +
                    '/episodes/' +
                    episode.id +
                    '/change-date'
                  }
                  className="btn btn-primary"
                >
                  Change Date
                </Link>
              ) : null}
              <Link
                to={
                  '/edu/series/' +
                  series.id +
                  '/episodes/' +
                  episode.id +
                  '/mark-updated'
                }
                className="btn btn-primary"
              >
                Mark Updated
              </Link>
              <Link
                to={
                  '/edu/series/' +
                  series.id +
                  '/episodes/' +
                  episode.id +
                  '/demo-video'
                }
                className="btn btn-primary"
              >
                Demo Video
              </Link>
              <button className="btn btn-primary" onClick={loadVideo}>
                Load video
              </button>
              {episode.status === 'active' ? (
                <a
                  href={
                    process.env.REACT_APP_FRONTEND_URL +
                    '/edu/series/' +
                    series.slug +
                    '/' +
                    episode.slug
                  }
                  style={{ marginLeft: 'auto' }}
                  className="btn btn-success"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  View on site
                </a>
              ) : null}
            </p>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Slug</th>
                  <td>{episode.slug}</td>
                </tr>
                <tr>
                  <th>Sort</th>
                  <td>{episode.sort}</td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">{episode.date}</Moment>
                  </td>
                </tr>
                <tr>
                  <th>Update Date</th>
                  <td>
                    {episode.update_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {episode.update_date}
                      </Moment>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Publish Date</th>
                  <td>
                    {episode.publish_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {episode.publish_date}
                      </Moment>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Free</th>
                  <td>{episode.free ? 'free' : ''}</td>
                </tr>
                <tr>
                  <th>Promoted</th>
                  <td>{episode.promoted ? 'yes' : ''}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{episode.status}</td>
                </tr>
                <tr>
                  <th>Title</th>
                  <td>{episode.content.title}</td>
                </tr>
                <tr>
                  <th>Video</th>
                  <td>
                    {episode.video ? (
                      <div>
                        <a href={episode.video.url}>{episode.video.url}</a>
                        <br />
                        {formatDuration(episode.video.duration)}{' '}
                        {episode.video.subtitles ? 'CC' : null}
                      </div>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Video Demo</th>
                  <td>
                    {episode.video_demo ? (
                      <div>
                        <a href={episode.video_demo.url}>
                          {episode.video_demo.url}
                        </a>
                        <br />
                        {formatDuration(episode.video_demo.duration)}
                      </div>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Poster</th>
                  <td>
                    {episode.content.poster ? (
                      <img
                        src={episode.content.poster}
                        style={{ maxWidth: '320px' }}
                        alt=""
                      />
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Thumbnail</th>
                  <td>
                    {episode.content.thumbnail ? (
                      <img
                        src={episode.content.thumbnail}
                        style={{ maxWidth: '320px' }}
                        alt=""
                      />
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Meta Title</th>
                  <td>{episode.meta.title}</td>
                </tr>
                <tr>
                  <th>Meta Description</th>
                  <td>{episode.meta.description}</td>
                </tr>
                <tr>
                  <th>Authors</th>
                  <td>
                    {episode.authors.map((author) => (
                      <span key={author.id} className="badge badge-primary">
                        {author.name}
                      </span>
                    ))}
                  </td>
                </tr>
                <tr>
                  <th>Tags</th>
                  <td>
                    {episode.tags.map((tag) => (
                      <span key={tag.slug} className="badge badge-primary">
                        {tag.name}
                      </span>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="card mb-3">
              <div className="card-body">{episode.content.short}</div>
            </div>
            <div className="card">
              <div className="card-body">{episode.content.description}</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
