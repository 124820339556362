import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../../../context/auth/AuthContext'
import handleResponse from '../../../../../../handleResponse'
import parseErrorMessage from '../../../../../../parseErrorMessage'

export default function EduGroupsGroupTagsTagShow() {
  const [token, setToken] = useContext(AuthContext)
  const params = useParams()

  const [group, setGroup] = useState(null)
  const [tag, setTag] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch(
      '/api/edge/admin/edu/groups/' + params.group + '/tags/' + params.tag,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then(handleResponse)
      .then((data) => {
        setGroup(data.group)
        setTag(data.tag)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [token, setToken, setGroup, setTag, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/edu/groups">Groups</Link>
          </li>
          {group ? (
            <li className="breadcrumb-item">
              <Link to={'/edu/groups/' + group.id}>{group.name}</Link>
            </li>
          ) : null}
          {tag ? <li className="breadcrumb-item active">{tag.name}</li> : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}
        {tag ? (
          <div>
            <p className="controls">
              <Link
                to={'/edu/groups/' + group.id + '/tags/' + tag.id + '/edit'}
                className="btn btn-primary"
              >
                Edit
              </Link>
            </p>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{tag.name}</td>
                </tr>
                <tr>
                  <th>Slug</th>
                  <td>{tag.slug}</td>
                </tr>
                <tr>
                  <th>Sort</th>
                  <td>{tag.sort}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}
