import React, { useState } from 'react'
import './Layout.scss'
import NavBar from './NavBar'
import Routes from './Routes'

const Layout = () => {
  const [showSidebar, setShowSidebar] = useState(false)

  function toggleSidebar() {
    setShowSidebar(!showSidebar)
  }

  return (
    <div
      className={
        'app header-fixed sidebar-fixed sidebar-lg-show' +
        (showSidebar ? ' sidebar-show' : '')
      }
    >
      <header className="app-header navbar">
        <button
          className="navbar-toggler sidebar-toggler d-lg-none mr-auto"
          type="button"
          onClick={toggleSidebar}
        >
          <span className="navbar-toggler-icon" />
        </button>
      </header>
      <div className="app-body">
        <div className="sidebar">
          <NavBar />
        </div>
        <main className="main">
          <Routes />
        </main>
      </div>
    </div>
  )
}

export default Layout
