import React, { useContext, useState } from 'react'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import parseValidationErrors from '../../../parseValidationErrors'
import { Link } from 'react-router-dom'

export default function EduGroupsCreate() {
  const [token, setToken] = useContext(AuthContext)

  const [formData, setFormData] = useState({
    name: '',
    sort: 0,
  })

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('/api/edge/admin/edu/groups', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(formData),
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setErrors(parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/edu/groups">Groups</Link>
          </li>
          <li className="breadcrumb-item active">Create</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        <form method="post" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              name="name"
              value={formData.name}
              onChange={handleChange}
              type="text"
              className={'form-control' + (errors.name ? ' is-invalid' : '')}
              placeholder="Name"
              required
            />
            <div className="invalid-feedback">{errors.name}</div>
          </div>
          <div className="form-group">
            <input
              name="sort"
              value={formData.sort}
              type="number"
              className={'form-control' + (errors.sort ? ' is-invalid' : '')}
              placeholder="Sort"
              required
            />
            <div className="invalid-feedback">{errors.sort}</div>
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}
