import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import handleResponse from '../../../handleResponse'
import parseErrorMessage from '../../../parseErrorMessage'
import Pagination from '../../../components/Pagination'
import Moment from 'react-moment'
import usePage from '../../../usePage'
import styles from './Index.module.scss'

function PaymentSubscriptionsIndex() {
  const [token, setToken] = useContext(AuthContext)

  const [subscriptions, setSubscriptions] = useState(null)
  const [pagination, setPagination] = useState(null)
  const [error, setError] = useState(null)

  const page = usePage()

  useEffect(() => {
    fetch('/api/edge/admin/payment/subscriptions?page=' + page, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setSubscriptions(data.items)
        setPagination(data.pagination)
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [page, token, setToken, setSubscriptions, setPagination, setError])

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Subscriptions</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {subscriptions ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Order</th>
                  <th>Activate</th>
                  <th />
                  <th style={{ textAlign: 'center' }} title="Pays">
                    P
                  </th>
                  <th>Account</th>
                  <th
                    style={{ textAlign: 'center' }}
                    title="Other Subscriptions"
                  >
                    S
                  </th>
                  <th>External ID</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((subscription) => (
                  <tr
                    key={subscription.id}
                    className={
                      subscription.without_post_receipts_count
                        ? styles.wpr
                        : subscription.is_first
                          ? styles.first
                          : ''
                    }
                  >
                    <td>
                      <Link to={'/payment/subscriptions/' + subscription.id}>
                        {subscription.id.split('-')[0]}...
                      </Link>
                    </td>
                    <td>
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {subscription.date}
                      </Moment>
                    </td>
                    <td>
                      {subscription.activate_date ? (
                        <Moment format="YYYY-MM-DD HH:mm:ss">
                          {subscription.activate_date}
                        </Moment>
                      ) : null}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {subscription.amount}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {subscription.payments_count ? (
                        <span>{subscription.payments_count}</span>
                      ) : null}
                    </td>
                    <td>
                      <Link to={'/payment/accounts/' + subscription.account.id}>
                        {subscription.account.email}
                      </Link>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {subscription.account_other_subscriptions_count ? (
                        <span>
                          {subscription.account_other_subscriptions_count}
                        </span>
                      ) : null}
                    </td>
                    <td>
                      {subscription.external_id
                        ? subscription.external_id.slice(1, 10) + '...'
                        : ''}
                    </td>
                    <td>{subscription.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        {pagination ? (
          <Pagination
            page={page}
            totalRows={pagination.total}
            perPage={pagination.per_page}
          />
        ) : null}
      </div>
    </div>
  )
}

export default PaymentSubscriptionsIndex
